<template>
  <ui-component-modal
    modalTitle="Delete space"
    :buttonLabelConfirm="'Delete'"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteSpace"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          Are you sure you want to delete this space {{ space.Name }}?
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="'Yes, I am sure!'"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import spaceProvider from '@/providers/space'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    space: null,
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  methods: {
    deleteSpace() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true
      }
    },
  },
}
</script>
